import axios from '@/axios.js'

export default {
    getGeofences: async (appUuid, params) => {
        try {
            const response = await axios.editorapi.get(`geofences/v3/apps/${appUuid}`, { params })
            return response.data.object
        } catch (error) {
            throw new Error(error)
        } 
    },
    getGeofence: async (appUuid, geofenceUuid) => {
        try {
            const response = await axios.editorapi.get(`geofences/v3/apps/${appUuid}/${geofenceUuid}`)
            return response.data.object
        } catch (error) {
            throw new Error(error)
        } 
    },
    getActivationPois: async(appUuid, page = 1) => {
        try {
            const response = await axios.editorapi.get(`geofences/v3/apps/${appUuid}/activation-pois?page=${page}`)
            return response.data.object
        } catch (error) {
            throw new Error(error)
        } 
    },

    deleteGeofence: async (appUuid, geofenceUuid) => {
        try {
            const response = await axios.editorapi.delete(`geofences/v3/apps/${appUuid}/${geofenceUuid}`)
            return response.data
        } catch (error) {
            throw new Error(error)
        } 
    },
    createGeofence: async (appUuid, payload) => {
        try {
            const response = await axios.editorapi.post(`geofences/v3/apps/${appUuid}/`, payload)
            return response.data
        } catch (error) {
            throw new Error(error)
        } 
    },
    updateGeofence: async (appUuid, geofenceUuid, payload) => {
        try {
            const response = await axios.editorapi.put(`geofences/v3/apps/${appUuid}/${geofenceUuid}`, payload)
            return response.data
        } catch (error) {
            throw new Error(error)
        } 
    },
}