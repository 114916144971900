var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("DatatablePageLayout", {
    scopedSlots: _vm._u([
      {
        key: "actionButtons",
        fn: function() {
          return [
            _c(
              "vs-button",
              {
                staticClass: "rounded-lg xs:w-full sm:w-auto",
                attrs: { to: { name: "create-geofence" } }
              },
              [
                _vm._v(
                  " " + _vm._s(_vm.$t("geofences.add-geofences.title")) + " "
                )
              ]
            )
          ]
        },
        proxy: true
      },
      {
        key: "datatable",
        fn: function() {
          return [
            _c("Datatable", {
              attrs: {
                config: _vm.datatableConfig,
                elements: _vm.geofences,
                stripe: true,
                sst: true,
                pagination: _vm.pagination
              },
              on: { filter: _vm.handleFilter, action: _vm.handleAction }
            })
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }