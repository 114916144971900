<template>
    <div class="w-full vx-row md:w-2/3 lg:w-1/2">
        <vx-card>
        <div class="mt-3 vx-row">
            <header class="w-full vx-col ">
                <div v-if="geofenceUuid">
                    <h2 >{{ $t("geofences.update-geofences.title") }}</h2>
                    <p>{{ $t("geofences.update-geofences.subtitle") }}</p>
                </div>
                <div v-else>
                    <h2>{{ $t("geofences.add-geofences.title") }}</h2>
                    <p>{{ $t("geofences.add-geofences.subtitle") }}</p>
                </div>
            </header>
        </div>

        <LabelForm>{{ $t("geofences.add-geofences.reference") }} *</LabelForm>
        <vs-input class="w-full border-grey-light" v-model="geofence.reference"/>

        <LabelForm>{{ $t("geofences.add-geofences.message") }} *</LabelForm>
        <b-tabs class="m-0" nav-class="mb-3">
            <b-tab v-for="(description, index) in geofence.descriptions" :key="'description'+index"
                :title="description.language"
                v-bind:active="index === 0" class=""
            >
                <InputTextCounter classProp="w-full" type="textarea" :min="10" :max="150" v-model="geofence.descriptions[index].description"/>
            </b-tab>
        </b-tabs>

        <LabelForm>{{ $t("geofences.add-geofences.url") }}</LabelForm>
        <vs-input class="w-full border-grey-light" v-model="geofence.url"/>

        <LabelForm>{{ $t("geofences.add-geofences.activation-pois") }} *</LabelForm>
        <div class="p-2 m-2 bg-grey-light xs:w-full sm:w-2/3" v-for="(coordinate, index) in geofence.coordinates" :key="'coordinate'+index">
            <div class="xs:w-full sm:w-4/5 mb-3">
                <div class="float-right cursor-pointer" @click="removeCoordinate(index)">X</div>
                <span class="block mt-2">{{ $t("geofences.add-geofences.latitude")}}</span>
                <vs-input class="w-full border-grey-light" v-model="coordinate.lat"/>
                <span class="block mt-2">{{ $t("geofences.add-geofences.longitude")}}</span>
                <vs-input class="w-full border-grey-light" v-model="coordinate.lon"/>
                <span class="block mt-2">{{ $t("geofences.add-geofences.radius") }}</span>
                <vs-input class="w-full border-grey-light" type="number" min="1" v-model="coordinate.radius"/>
            </div>

            <div class="w-full">
                <GmapMap
                    :center="{lat: coordinate.lat, lng: coordinate.lon}"
                    :zoom="6"
                    map-type-id="satellite"
                    style="max-width: 100%; height: 300px"
                    :options="{streetViewControl: false}"
                    @click="(evt) => changeActivationPoiCoords(evt, index)"
                >
                    <GmapMarker
                        ref="map"
                        :draggable="true"
                        :position="google && new google.maps.LatLng(coordinate.lat, coordinate.lon)"
                        @dragend="(evt) => changeActivationPoiCoords(evt, index)"
                    />
                </GmapMap>
            </div>
        </div>

        <vs-button
            type="border"
            class="mt-8 rounded-lg xs:w-full sm:w-auto"
            @click.prevent="addCoordinate">
            {{ $t("geofences.add-geofences.add-poi-manually") }}
        </vs-button>

         <vs-select
            :label="$t('geofences.add-geofences.timezone') + ' *'"
            v-model="geofence.timezone"
            class="w-full mt-8 sm:w-1/2">
            <vs-select-item v-for="timezone in timezones" :key="timezone.value" :value="timezone.value" :text="timezone.label"/>
        </vs-select>

        <div class="vx-row w-full">
            <div class="vx-col xs:w-full sm:w-1/2">
                <LabelForm>{{ $t("geofences.add-geofences.start-date") }} *</LabelForm>
                <b-form-datepicker  class="w-48 mb-1 rounded-lg"
                                    today-button
                                    reset-button
                                    close-button
                                    v-model="start"
                                    placeholder=""
                                    :name="start"
                                    :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                                    :locale="applicationLanguage" />
            </div>

            <div class="vx-col xs:w-full sm:w-1/2">
                <LabelForm>{{ $t("geofences.add-geofences.end-date") }} *</LabelForm>
                <b-form-datepicker  class="w-48 mb-1 rounded-lg"
                                    today-button
                                    reset-button
                                    close-button
                                    v-model="end"
                                    placeholder=""
                                    :name="end"
                                    :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                                    :locale="applicationLanguage" />
            </div>
        </div>

        <LabelForm>{{ $t("geofences.add-geofences.week-days") }} *</LabelForm>
        <div v-if="geofence.activation_week_days" class="w-full vx-row lg:w-full">
            <CheckBoxForm v-for="weekday in weekdays" :key="weekday" class="w-full mb-4 text-sm vx-col md:w-1/3" @isChecked="(value) => {checkDay(weekday, value)}" :checked="isDaySelected(weekday)"> {{ getDayName(weekday) }} </CheckBoxForm>
        </div>

        <vs-select
            :label="$t('geofences.add-geofences.published') + ' *'"
            v-model="geofence.publish"
            class="w-full mt-8 sm:w-1/2">
            <vs-select-item v-for="option in [{'value':0,'label':$t('no')},{'value':1,'label':$t('yes')}]" :key="option.value" :value="option.value" :text="option.label"/>
        </vs-select>

        <b-alert :show="errorsOnSave.length > 0" variant="danger" class="my-5 rounded-lg">
            <ul class="list-none">
                <li v-for="(errorString, index) in errorsOnSave" :key="index">{{ errorString }}</li>
            </ul>
        </b-alert>

        <div class="mt-8">
            <vs-button color="primary" type="filled rounded-lg w-48 mr-10" @click="saveGeofence()">{{ $t("geofences.add-geofences.save") }}</vs-button>
            <vs-button color="#242426" type="border" class="w-48 rounded-lg" @click="$router.push(`/apps/${applicationUuid}/geofences`)">{{ $t("geofences.add-geofences.cancel") }}</vs-button>
        </div>
        </vx-card>
        </div>
</template>
<script>
import LabelForm from '@/modules/Shared/Components/form/LabelForm'
import InputTextCounter from '@/modules/Shared/Components/form/InputTextCounter'
import CheckBoxForm from '@/modules/Shared/Components/form/CheckBoxForm'
import AppsService from '@/modules/Apps/Services/AppsService'
import GeofenceService from '@/modules/Apps/Services/GeofenceService'
import localStorageHelper from '@/modules/Shared/Helpers/localStorageHelper'
import { getFreqOptions, prepareFrequencyResponse, prepareDescriptionsResponse } from "@/modules/Apps/Helpers/geofenceHelper"
import timezones from '@/timezones.json'
import { mapGetters } from 'vuex'
import loader from "@/modules/Shared/Mixins/loader.js";
import { gmapApi } from 'vue2-google-maps'
import store from "@/modules/Shared/Store/store";

export default {
    name: 'geofence-form',
    components: {
        LabelForm,
        InputTextCounter,
        CheckBoxForm
    },
    mixins: [loader],
    data: () => (
        {
            weekdays:['mo', 'tu', 'we', 'th', 'fr', 'sa' , 'su'],
            geofence: {},
            appLanguages: {},
            languages: {},
            errorsOnSave:[],

            publish_start:'',
            publish_end:'',
            start:'',
            end:'',
            freqOptions:[],
            timezones:[]
        }
    ),
    async beforeRouteEnter (to, from, next) {
        const geofence = to.params.geofenceUuid
            ? await GeofenceService.getGeofence(to.params.uuid, to.params.geofenceUuid)
            : null
        await store.dispatch('setRouteElement', geofence)
        next(vm => vm.geofence = geofence)
    },
    props: {
        geofenceUuid: {type: String},
    },
    computed: {
        ...mapGetters( ['applicationUuid', 'applicationLanguage', 'AppActiveUser']),
        google: gmapApi,

    },

    async beforeCreate(){
        this.timezones = Array.from(await timezones,x => {return {label:x, value:x}})
        this.freqOptions = getFreqOptions()
    },
    async created(){
        await this.load(async() => {
            this.appLanguages = await AppsService.getLanguages(this.applicationUuid)
            if (!this.geofenceUuid) {
                this.geofence = this.buildGeofenceStructure()
            }

            this.start  = this.geofence.publish_start?this.geofence.publish_start.split(' ')[0]:''
            this.end    = this.geofence.publish_end?this.geofence.publish_end.split(' ')[0]:''

            this.fillFormFields()
        })
    },
    destroyed() {
        localStorageHelper.remove('geofence')
    },
    methods:{
        changeActivationPoiCoords(evt, index) {
            this.geofence.coordinates[index].lat = evt.latLng.lat().toFixed(6)
            this.geofence.coordinates[index].lon = evt.latLng.lng().toFixed(6)
        },
        async fillFormFields() {
            const geofenceEdited = localStorageHelper.set('geofence',this.geofence)
            if(geofenceEdited) {
                this.reference = geofenceEdited.reference
            }
        },
        getDayName(acronym){
            switch(acronym){
                case 'mo': return this.$t("geofences.add-geofences.monday")
                case 'tu': return this.$t("geofences.add-geofences.tuesday")
                case 'we': return this.$t("geofences.add-geofences.wednesday")
                case 'th': return this.$t("geofences.add-geofences.thursday")
                case 'fr': return this.$t("geofences.add-geofences.friday")
                case 'sa': return this.$t("geofences.add-geofences.saturday")
                case 'su': return this.$t("geofences.add-geofences.sunday")

            }
        },
        buildGeofenceStructure(){
            return {
                activation_pois:[],
                activation_week_days:[],
                coordinates:[{lat:0,lon:0,radius:100}],
                description:'',
                descriptions:[...this.appLanguages].map((language) => {return { 'description': '', 'language': language.acronym}}),
                last_update:null,
                publish:0,
                publish_end:'',
                publish_start:'',
                reference:'',
                status:'not_active',
                timezone:'Europe/Paris',
                url:'',
                user_last_update:null,
            }
        },
        checkDay(day, value){
            if(this.geofence){
                if(!value){
                    this.geofence.activation_week_days.splice(this.daySelected( day ), 1)
                }else{
                    if(this.daySelected(day) === -1){
                        this.geofence.activation_week_days.push(day)
                    }
                }
            }
        },
        daySelected(day){
            return this.geofence.activation_week_days.indexOf( day )
        },
        isDaySelected(day){
            return this.daySelected( day ) !== -1
        },

        addCoordinate(){
            this.geofence.coordinates = [...this.geofence.coordinates, {lat:0,lon:0,radius:100}]
        },
        removeCoordinate(key){
            this.$delete(this.geofence.coordinates, key)
        },
        hasErrors() {
            this.errorsOnSave = []

            if (this.geofence.reference.trim().length === 0) {
                this.errorsOnSave = [...this.errorsOnSave, this.$t("geofences.add-geofences.reference-required")]
            }

            if(this.geofence.descriptions.every(description => description.description.length === 0)){
                this.errorsOnSave = [...this.errorsOnSave, this.$t("geofences.add-geofences.description-required")]
            }

            if(this.publish_start.trim().length === 0) {
                this.errorsOnSave.push(this.$t("geofences.add-geofences.start-date-required"))
            }

            if(this.publish_end.trim().length === 0) {
                this.errorsOnSave.push(this.$t("geofences.add-geofences.end-date-required"))
            }

            if(this.publish_start && this.publish_end && this.publish_start >= this.publish_end) {
                this.errorsOnSave.push(this.$t("geofences.add-geofences.error-dates"))
            }

            if(this.geofence.activation_week_days.length === 0) {
                this.errorsOnSave.push(this.$t("geofences.add-geofences.week-days-required"))
            }

            return this.errorsOnSave.length !== 0
        },
        async saveGeofence(){
            this.errorsOnSave = []

            this.publish_end = this.end + ' 23:59:59'
            this.publish_start = this.start + ' 00:00:00'
            const payload = {
                week_days:this.geofence.activation_week_days,
                coordinates:this.geofence.coordinates,
                descriptions: prepareDescriptionsResponse(this.geofence.descriptions),
                publish:this.geofence.publish,
                publish_end:this.publish_end,
                publish_start:this.publish_start,
                frequency: prepareFrequencyResponse(this.geofence.freq),
                reference: this.geofence.reference,
                timezone:this.geofence.timezone,

            }
            if(this.geofence.url && this.geofence.url.length > 0) {
                payload.url = this.geofence.url
            }

            let response;
            if (!this.hasErrors()) {
                await this.load(async () => {
                    if (this.geofenceUuid) {
                        response = await GeofenceService.updateGeofence(this.applicationUuid, this.geofenceUuid, payload)
                    } else {
                        response = await GeofenceService.createGeofence(this.applicationUuid, payload)
                    }
                })
            }
            if (response.code == 200) {
                const text = this.geofenceUuid ? this.$t("geofences.update-geofences.ok") : this.$t("geofences.create-geofences.ok")
                this.$vs.notify( { text: text, color: 'success',position:'top-right' } )
                setTimeout(() => {
                    this.$router.push({'name':'geofences','params':{'uuid':this.applicationUuid}});
                }, 1000)
            } else {
                this.$vs.notify( { text: this.geofenceUuid ? this.$t("geofences.update-geofences.ko") : this.$t("geofences.create-geofences.ko"), color: 'danger',position:'top-right'})
            }
        },

    }

}
</script>
