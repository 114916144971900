<template>
    <DatatablePageLayout>
        <template v-slot:actionButtons>
            <vs-button 
                class="rounded-lg xs:w-full sm:w-auto" 
                :to="{ name: 'create-geofence' }" >
                {{ $t("geofences.add-geofences.title") }}
            </vs-button>
        </template>
        <template v-slot:datatable>
            <Datatable 
                :config="datatableConfig"
                :elements="geofences"
                :stripe="true"
                :sst="true"
                @filter="handleFilter"
                @action="handleAction"
                :pagination="pagination"
            />
        </template>
    </DatatablePageLayout>
</template>
<script>
import {DateTime} from 'luxon';
import Datatable from '@/modules/Shared/Components/table/SKDataTable.vue'
import GeofenceService from '@/modules/Apps/Services/GeofenceService'
import PaginationService from '@/modules/Shared/Services/PaginationService'
import {mapGetters} from 'vuex';
import { getReconsiderSendingStringsByFreq, getWeekdayOptions } from "@/modules/Apps/Helpers/geofenceHelper"
import loader from "@/modules/Shared/Mixins/loader.js";
import DatatablePageLayout from "@/modules/Shared/Pages/DatatablePageLayout";

export default {
    name: 'geofences',
    components: {
        DatatablePageLayout,
        Datatable
    },
    mixins: [loader],
    data() {
        return {
        geofences: [],
        datatableConfig: [
            { 'field' : 'reference', head : this.$t("geofences.geofences-messages.reference"), 'searchable' : true, 'sortable' : true},
            { 'field' : 'weekday', head : this.$t("geofences.geofences-messages.week-days-activation"), 'searchable' : true, 'searchableoptions':getWeekdayOptions(), 'sortable' : true},            
            { 'field' : 'dailyActivationPeriod', head : this.$t("geofences.geofences-messages.daily-activation-period"), 'searchable' : false, 'sortable' : true},
            { 'field' : 'publishStart', head : this.$t("geofences.geofences-messages.publish-date"), 'searchable' : false, 'sortable' : true},
            { 'field' : 'publishEnd', head : this.$t("geofences.geofences-messages.end-date"), 'searchable' : false, 'sortable' : true},
            { 'field' : 'actions', 'type': 'actions', head : this.$t("geofences.geofences-messages.actions"), 'searchable' : false, 'sortable' : false }
        ],
        pagination: {},
    }},
    computed: {
        ...mapGetters( ['applicationUuid']),
    },
    async created() { 
        await this.getGeofences( {} )         
    },
    methods: {
        async getGeofences( params ) {   
            await this.load(async () => {
                const geofences =  await GeofenceService.getGeofences(this.applicationUuid, params)
                
                this.geofences =  this.parseGeofences( geofences.data )     
                this.pagination = {
                    per_page: geofences.per_page,
                    total: geofences.total
                } 
            })
        },
        parseGeofences( geofences ) {
            geofences.forEach(geofence => {
                geofence.uuid = geofence.uuid
                geofence.user = geofence.user ? geofence.user.username : null
                geofence.updated = geofence.updated ? DateTime.fromSQL(geofence.updated).toFormat('dd/LL/yyyy HH:mm:ss') : null
                geofence.freq = getReconsiderSendingStringsByFreq(geofence.freq)

                geofence.publishStart = geofence.publishstart ? DateTime.fromSQL(geofence.publishstart).toFormat('dd/LL/yyyy') : null
                geofence.publishEnd = geofence.publishend ? DateTime.fromSQL(geofence.publishend).toFormat('dd/LL/yyyy') : null
                geofence.dailyActivationPeriod = geofence.daily_activation_period
                geofence.weekday = geofence.weekdays.length === 7 ? this.$t("geofences.add-geofences.everyday") :  geofence.weekdays.join(', ')
                
                geofence.actions =  [ 'delete','edit' ]
            })
            return geofences
        },
        async deleteGeofence(uuid) {
            let response = undefined
            await this.load(async () => {
                response = await GeofenceService.deleteGeofence(this.applicationUuid, uuid);
            })

            if(response.code == 200) {
                this.geofences = this.geofences.filter((contact) => contact.uuid !== uuid)
                this.$vs.notify( { text: this.$t("geofences.remove-geofences.ok"), color: 'success',position:'top-right' } )
            } else {
                this.$vs.notify( { text: this.$t("geofences.remove-geofences.ko"), color: 'danger',position:'top-right'} )
            }
        },

        handleAction(type, uuid){
            switch(type) {
                case 'edit': 
                    this.$router.push({
                        name: 'edit-geofence',
                        params: {
                            'geofenceUuid': uuid,
                        }
                    });
                break;
                case 'delete':
                    this.deleteGeofence(uuid)
                break;
            }
        },
        handleFilter(params) {
            this.getGeofences(PaginationService.parseParameters(params))
        }
    }
}
</script>