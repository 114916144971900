var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "w-full vx-row md:w-2/3 lg:w-1/2" },
    [
      _c(
        "vx-card",
        [
          _c("div", { staticClass: "mt-3 vx-row" }, [
            _c("header", { staticClass: "w-full vx-col " }, [
              _vm.geofenceUuid
                ? _c("div", [
                    _c("h2", [
                      _vm._v(_vm._s(_vm.$t("geofences.update-geofences.title")))
                    ]),
                    _c("p", [
                      _vm._v(
                        _vm._s(_vm.$t("geofences.update-geofences.subtitle"))
                      )
                    ])
                  ])
                : _c("div", [
                    _c("h2", [
                      _vm._v(_vm._s(_vm.$t("geofences.add-geofences.title")))
                    ]),
                    _c("p", [
                      _vm._v(_vm._s(_vm.$t("geofences.add-geofences.subtitle")))
                    ])
                  ])
            ])
          ]),
          _c("LabelForm", [
            _vm._v(_vm._s(_vm.$t("geofences.add-geofences.reference")) + " *")
          ]),
          _c("vs-input", {
            staticClass: "w-full border-grey-light",
            model: {
              value: _vm.geofence.reference,
              callback: function($$v) {
                _vm.$set(_vm.geofence, "reference", $$v)
              },
              expression: "geofence.reference"
            }
          }),
          _c("LabelForm", [
            _vm._v(_vm._s(_vm.$t("geofences.add-geofences.message")) + " *")
          ]),
          _c(
            "b-tabs",
            { staticClass: "m-0", attrs: { "nav-class": "mb-3" } },
            _vm._l(_vm.geofence.descriptions, function(description, index) {
              return _c(
                "b-tab",
                {
                  key: "description" + index,
                  attrs: { title: description.language, active: index === 0 }
                },
                [
                  _c("InputTextCounter", {
                    attrs: {
                      classProp: "w-full",
                      type: "textarea",
                      min: 10,
                      max: 150
                    },
                    model: {
                      value: _vm.geofence.descriptions[index].description,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.geofence.descriptions[index],
                          "description",
                          $$v
                        )
                      },
                      expression: "geofence.descriptions[index].description"
                    }
                  })
                ],
                1
              )
            }),
            1
          ),
          _c("LabelForm", [
            _vm._v(_vm._s(_vm.$t("geofences.add-geofences.url")))
          ]),
          _c("vs-input", {
            staticClass: "w-full border-grey-light",
            model: {
              value: _vm.geofence.url,
              callback: function($$v) {
                _vm.$set(_vm.geofence, "url", $$v)
              },
              expression: "geofence.url"
            }
          }),
          _c("LabelForm", [
            _vm._v(
              _vm._s(_vm.$t("geofences.add-geofences.activation-pois")) + " *"
            )
          ]),
          _vm._l(_vm.geofence.coordinates, function(coordinate, index) {
            return _c(
              "div",
              {
                key: "coordinate" + index,
                staticClass: "p-2 m-2 bg-grey-light xs:w-full sm:w-2/3"
              },
              [
                _c(
                  "div",
                  { staticClass: "xs:w-full sm:w-4/5 mb-3" },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "float-right cursor-pointer",
                        on: {
                          click: function($event) {
                            return _vm.removeCoordinate(index)
                          }
                        }
                      },
                      [_vm._v("X")]
                    ),
                    _c("span", { staticClass: "block mt-2" }, [
                      _vm._v(_vm._s(_vm.$t("geofences.add-geofences.latitude")))
                    ]),
                    _c("vs-input", {
                      staticClass: "w-full border-grey-light",
                      model: {
                        value: coordinate.lat,
                        callback: function($$v) {
                          _vm.$set(coordinate, "lat", $$v)
                        },
                        expression: "coordinate.lat"
                      }
                    }),
                    _c("span", { staticClass: "block mt-2" }, [
                      _vm._v(
                        _vm._s(_vm.$t("geofences.add-geofences.longitude"))
                      )
                    ]),
                    _c("vs-input", {
                      staticClass: "w-full border-grey-light",
                      model: {
                        value: coordinate.lon,
                        callback: function($$v) {
                          _vm.$set(coordinate, "lon", $$v)
                        },
                        expression: "coordinate.lon"
                      }
                    }),
                    _c("span", { staticClass: "block mt-2" }, [
                      _vm._v(_vm._s(_vm.$t("geofences.add-geofences.radius")))
                    ]),
                    _c("vs-input", {
                      staticClass: "w-full border-grey-light",
                      attrs: { type: "number", min: "1" },
                      model: {
                        value: coordinate.radius,
                        callback: function($$v) {
                          _vm.$set(coordinate, "radius", $$v)
                        },
                        expression: "coordinate.radius"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "w-full" },
                  [
                    _c(
                      "GmapMap",
                      {
                        staticStyle: { "max-width": "100%", height: "300px" },
                        attrs: {
                          center: { lat: coordinate.lat, lng: coordinate.lon },
                          zoom: 6,
                          "map-type-id": "satellite",
                          options: { streetViewControl: false }
                        },
                        on: {
                          click: function(evt) {
                            return _vm.changeActivationPoiCoords(evt, index)
                          }
                        }
                      },
                      [
                        _c("GmapMarker", {
                          ref: "map",
                          refInFor: true,
                          attrs: {
                            draggable: true,
                            position:
                              _vm.google &&
                              new _vm.google.maps.LatLng(
                                coordinate.lat,
                                coordinate.lon
                              )
                          },
                          on: {
                            dragend: function(evt) {
                              return _vm.changeActivationPoiCoords(evt, index)
                            }
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              ]
            )
          }),
          _c(
            "vs-button",
            {
              staticClass: "mt-8 rounded-lg xs:w-full sm:w-auto",
              attrs: { type: "border" },
              on: {
                click: function($event) {
                  $event.preventDefault()
                  return _vm.addCoordinate.apply(null, arguments)
                }
              }
            },
            [
              _vm._v(
                " " +
                  _vm._s(_vm.$t("geofences.add-geofences.add-poi-manually")) +
                  " "
              )
            ]
          ),
          _c(
            "vs-select",
            {
              staticClass: "w-full mt-8 sm:w-1/2",
              attrs: {
                label: _vm.$t("geofences.add-geofences.timezone") + " *"
              },
              model: {
                value: _vm.geofence.timezone,
                callback: function($$v) {
                  _vm.$set(_vm.geofence, "timezone", $$v)
                },
                expression: "geofence.timezone"
              }
            },
            _vm._l(_vm.timezones, function(timezone) {
              return _c("vs-select-item", {
                key: timezone.value,
                attrs: { value: timezone.value, text: timezone.label }
              })
            }),
            1
          ),
          _c("div", { staticClass: "vx-row w-full" }, [
            _c(
              "div",
              { staticClass: "vx-col xs:w-full sm:w-1/2" },
              [
                _c("LabelForm", [
                  _vm._v(
                    _vm._s(_vm.$t("geofences.add-geofences.start-date")) + " *"
                  )
                ]),
                _c("b-form-datepicker", {
                  staticClass: "w-48 mb-1 rounded-lg",
                  attrs: {
                    "today-button": "",
                    "reset-button": "",
                    "close-button": "",
                    placeholder: "",
                    name: _vm.start,
                    "date-format-options": {
                      year: "numeric",
                      month: "numeric",
                      day: "numeric"
                    },
                    locale: _vm.applicationLanguage
                  },
                  model: {
                    value: _vm.start,
                    callback: function($$v) {
                      _vm.start = $$v
                    },
                    expression: "start"
                  }
                })
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "vx-col xs:w-full sm:w-1/2" },
              [
                _c("LabelForm", [
                  _vm._v(
                    _vm._s(_vm.$t("geofences.add-geofences.end-date")) + " *"
                  )
                ]),
                _c("b-form-datepicker", {
                  staticClass: "w-48 mb-1 rounded-lg",
                  attrs: {
                    "today-button": "",
                    "reset-button": "",
                    "close-button": "",
                    placeholder: "",
                    name: _vm.end,
                    "date-format-options": {
                      year: "numeric",
                      month: "numeric",
                      day: "numeric"
                    },
                    locale: _vm.applicationLanguage
                  },
                  model: {
                    value: _vm.end,
                    callback: function($$v) {
                      _vm.end = $$v
                    },
                    expression: "end"
                  }
                })
              ],
              1
            )
          ]),
          _c("LabelForm", [
            _vm._v(_vm._s(_vm.$t("geofences.add-geofences.week-days")) + " *")
          ]),
          _vm.geofence.activation_week_days
            ? _c(
                "div",
                { staticClass: "w-full vx-row lg:w-full" },
                _vm._l(_vm.weekdays, function(weekday) {
                  return _c(
                    "CheckBoxForm",
                    {
                      key: weekday,
                      staticClass: "w-full mb-4 text-sm vx-col md:w-1/3",
                      attrs: { checked: _vm.isDaySelected(weekday) },
                      on: {
                        isChecked: function(value) {
                          _vm.checkDay(weekday, value)
                        }
                      }
                    },
                    [_vm._v(" " + _vm._s(_vm.getDayName(weekday)) + " ")]
                  )
                }),
                1
              )
            : _vm._e(),
          _c(
            "vs-select",
            {
              staticClass: "w-full mt-8 sm:w-1/2",
              attrs: {
                label: _vm.$t("geofences.add-geofences.published") + " *"
              },
              model: {
                value: _vm.geofence.publish,
                callback: function($$v) {
                  _vm.$set(_vm.geofence, "publish", $$v)
                },
                expression: "geofence.publish"
              }
            },
            _vm._l(
              [
                { value: 0, label: _vm.$t("no") },
                { value: 1, label: _vm.$t("yes") }
              ],
              function(option) {
                return _c("vs-select-item", {
                  key: option.value,
                  attrs: { value: option.value, text: option.label }
                })
              }
            ),
            1
          ),
          _c(
            "b-alert",
            {
              staticClass: "my-5 rounded-lg",
              attrs: { show: _vm.errorsOnSave.length > 0, variant: "danger" }
            },
            [
              _c(
                "ul",
                { staticClass: "list-none" },
                _vm._l(_vm.errorsOnSave, function(errorString, index) {
                  return _c("li", { key: index }, [_vm._v(_vm._s(errorString))])
                }),
                0
              )
            ]
          ),
          _c(
            "div",
            { staticClass: "mt-8" },
            [
              _c(
                "vs-button",
                {
                  attrs: {
                    color: "primary",
                    type: "filled rounded-lg w-48 mr-10"
                  },
                  on: {
                    click: function($event) {
                      return _vm.saveGeofence()
                    }
                  }
                },
                [_vm._v(_vm._s(_vm.$t("geofences.add-geofences.save")))]
              ),
              _c(
                "vs-button",
                {
                  staticClass: "w-48 rounded-lg",
                  attrs: { color: "#242426", type: "border" },
                  on: {
                    click: function($event) {
                      return _vm.$router.push(
                        "/apps/" + _vm.applicationUuid + "/geofences"
                      )
                    }
                  }
                },
                [_vm._v(_vm._s(_vm.$t("geofences.add-geofences.cancel")))]
              )
            ],
            1
          )
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }