var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", [
      _vm.type === "textarea"
        ? _c("textarea", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.text,
                expression: "text"
              }
            ],
            staticClass: "w-full h-32 rounded resize-none border-grey-light",
            class: _vm.classProp,
            domProps: { value: _vm.text },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.text = $event.target.value
              }
            }
          })
        : _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.text,
                expression: "text"
              }
            ],
            attrs: { type: "text" },
            domProps: { value: _vm.text },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.text = $event.target.value
              }
            }
          }),
      _c("p", { staticClass: "m-0 text-right", style: _vm.colorText }, [
        _vm._v(_vm._s(_vm.textCounter))
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }