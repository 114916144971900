<template>
    <div>
        <div>
            <textarea class="w-full h-32 rounded resize-none border-grey-light" :class="classProp" v-if="type === 'textarea'" v-model="text"></textarea>
            <input type="text" v-else v-model="text">
            <p class="m-0 text-right" :style="colorText">{{ textCounter }}</p>
        </div>
    </div>
</template>

<script>
export default {
    name: 'input-text-counter',
    props: {
        value: {type:String, default:''},
        type: {type:String, default:'text'},
        max: {type:Number, default:150},
        min: {type:Number, default:5},
        color: {type:String, default:'#00CC00'},
        colorAlert: {type:String, default:'#CC0000'},
        classProp: {type:String, default:'#00CC00'}
    }, 
    data: () => ({
            text: '',
        }
    ),
    created(){
        this.text = this.value !== null ? this.value : ''
    },
    computed: {
        colorText(){
            return  `color: ${(this.max - this.text.length) <= this.min ? this.colorAlert : this.color}`
        },
        textCounter(){

            return `${this.max - this.text.length}  ${this.max - this.text.length > 1 ? this.$t('characters') : this.$t('character')}` 
        }
    },
    watch: {
        text(newValue) {
            if(this.max - newValue.length < 0 ) {
                this.text = newValue.substr(0, this.max)
            }
            this.$emit('input', this.text)
        }
    }
    
}
</script>

<style>

</style>