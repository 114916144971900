import i18n from "@/i18n";

export function getReconsiderSendingStringsByFreq(freq) {
    switch (freq) {
        case 86400: return i18n.t("geofences.geofences-messages.one-day")
        case 172800: return i18n.t("geofences.geofences-messages.two-days")
        case 604800: return i18n.t("geofences.geofences-messages.one-week")
        case 2419200: return i18n.t("geofences.geofences-messages.one-month")
        case 29030400: return i18n.t("geofences.geofences-messages.one-year")
        default: return i18n.t("geofences.geofences-messages.not-reconsider")
    }
}

export function getFreqOptions() {
    return [
        {"label":i18n.t("geofences.geofences-messages.one-day"),"value":86400},
        {"label":i18n.t("geofences.geofences-messages.two-days"),"value":172800},
        {"label":i18n.t("geofences.geofences-messages.one-week"),"value":604800},
        {"label":i18n.t("geofences.geofences-messages.one-month"),"value":2419200},
        {"label":i18n.t("geofences.geofences-messages.one-year"),"value":29030400},
        {"label":i18n.t("geofences.geofences-messages.not-reconsider"),"value":290304000},
    ]
}

export function prepareFrequencyResponse(frequency) {
    const frequencyResponses = {
        86400:'1-day',
        172800:'2-days',
        604800:'1-week',
        2419200:'1-month',
        29030400:'1-year',
        290304000:'not-remind'
    }
    return frequencyResponses[frequency]
}
export function prepareDescriptionsResponse(descriptions) {
    const responses = {}
    descriptions.forEach(description => {
        responses[description.language] = description.description
    })
    return responses
}

export function getWeekdayOptions() {
    return [
        {"label":i18n.t("geofences.add-geofences.everyday"),"value":'everyday'},
        {"label":i18n.t("geofences.add-geofences.monday"),"value":'mo'},
        {"label":i18n.t("geofences.add-geofences.tuesday"),"value":'tu'},
        {"label":i18n.t("geofences.add-geofences.wednesday"),"value":'we'},
        {"label":i18n.t("geofences.add-geofences.thursday"),"value":'th'},
        {"label":i18n.t("geofences.add-geofences.friday"),"value":'fr'},
        {"label":i18n.t("geofences.add-geofences.saturday"),"value":'sa'},
        {"label":i18n.t("geofences.add-geofences.sunday"),"value":'su'},
    ]
}